import { Link } from 'react-router-dom';
import { ReadableSafSubmitterType, SAF_STATUS, type TSchoolSafItem } from '@purple/shared-types';
import { formatDate, snakeToCamelWithSpaces } from '@purple/shared-utils';
import { Badge, cn, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import type { ColumnDef } from '@tanstack/react-table';

export const safsReportColumns: ColumnDef<TSchoolSafItem>[] = [
  {
    accessorKey: 'saf_number',
    size: 80,
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600 uppercase">
        saf number
      </Text>
    ),
    cell: ({ row: { original } }) =>
      original.saf_number
        ? (
            <Link
              to={AppRoutes.App.Safs.SAFDetails.makePath(original.id)}
              className="font-primary text-brand-blue-700 cursor-pointer text-sm font-medium hover:underline hover:underline-offset-2"
            >
              {original.saf_number}
            </Link>
          )
        : (
            <span>—</span>
          ),
  },
  {
    accessorKey: 'submitter_type',
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600 uppercase">
        saf submitter type
      </Text>
    ),
    cell: ({ row: { original } }) => original.submitter_type
      ? (
          <Text variant="size-14" type="body-500" className="text-grey-950">
            {ReadableSafSubmitterType[original.submitter_type]}
          </Text>
        )
      : <span>—</span>,
  },
  {
    accessorKey: 'needs',
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600 uppercase">
        needs
      </Text>
    ),
    cell: ({ row: { original } }) => original.needs && original.needs.length > 0
      ? (
          <Tooltip>
            <TooltipTrigger asChild>
              <Text variant="size-14" type="body-500" className="text-grey-950 line-clamp-1 break-all">
                {original.needs.map((need) => need.name).join(', ')}
              </Text>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent withArrow={false} className="text-pretty">
                {original.needs.map((need) => need.name).join('\n')}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        )
      : <span>—</span>,
  },
  {
    accessorKey: 'linked_student',
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600 uppercase">
        linked student
      </Text>
    ),
    cell: ({ row: { original } }) => original.linked_student && original.linked_student.full_name && original.linked_student.full_name.length > 0
      ? (
          <Tooltip>
            <TooltipTrigger asChild>
              <Link
                to={AppRoutes.App.Students.StudentDetails.Root.makePath(original.linked_student.id)}
                className="font-primary text-brand-blue-700 line-clamp-1 cursor-pointer break-all text-sm font-medium hover:underline hover:underline-offset-2"
              >
                {original.linked_student.full_name}
              </Link>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent withArrow={false}>
                {original.linked_student.full_name}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        )
      : <span>—</span>,
  },
  {
    accessorKey: 'status',
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600 uppercase">
        status
      </Text>
    ),
    cell: ({ row: { original } }) => original.status
      ? (
          <Badge
            label={snakeToCamelWithSpaces(original.status)}
            className={cn({
              'bg-brand-blue-100 text-brand-blue-700': original.status === SAF_STATUS.NEW,
              'bg-success-bg text-success-main': original.status === SAF_STATUS.PRIORITIZED,
              'bg-warning-bg text-warning-main': original.status === SAF_STATUS.ON_HOLD,
              'bg-grey-100 text-grey-600': original.status === SAF_STATUS.CLOSED,
            })}
          />
        )
      : <span>—</span>,
    size: 100,
  },
  {
    accessorKey: 'owner_department',
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600 uppercase">
        saf owner department
      </Text>
    ),
    cell: ({ row: { original } }) => original.owner_department && original.owner_department.length > 0
      ? (
          <Tooltip>
            <TooltipTrigger asChild>
              <Text variant="size-14" type="body-500" className="text-grey-950 line-clamp-1 w-fit break-all">
                {original.owner_department}
              </Text>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent withArrow={false}>
                {original.owner_department}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        )
      : <span>—</span>,
    size: 120,
  },
  {
    accessorKey: 'saf_owner',
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600 uppercase">
        saf owner
      </Text>
    ),
    cell: ({ row: { original } }) => original.saf_owner && original.saf_owner.full_name && original.saf_owner.full_name.length > 0
      ? (
          <Tooltip>
            <TooltipTrigger asChild>
              <Text variant="size-14" type="body-500" className="text-grey-950 line-clamp-1 break-all">
                {original.saf_owner.full_name}
              </Text>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent withArrow={false}>
                {original.saf_owner.full_name}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        )
      : <span>—</span>,
  },
  {
    accessorKey: 'date_time_opened',
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600 uppercase">
        submitted date
      </Text>
    ),
    cell: ({ row: { original } }) => original.date_time_opened
      ? (
          <Text variant="size-14" type="body-500" className="text-grey-950">
            {formatDate(original.date_time_opened)}
          </Text>
        )
      : <span>—</span>,
  },
  {
    accessorKey: 'priority_assigned_at',
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600 uppercase">
        prioritized date
      </Text>
    ),
    cell: ({ row: { original } }) => original.priority_assigned_at
      ? (
          <Text variant="size-14" type="body-500" className="text-grey-950">
            {formatDate(original.priority_assigned_at)}
          </Text>
        )
      : <span>—</span>,
  },
];

import { useMemo } from 'react';
import { SAF_STATUS, type TNameableEntity, type TUserEntity } from '@purple/shared-types';
import { snakeCaseToCapitalized } from '@purple/shared-utils';
import { useSafsFilterOptions } from '~/queries/schools/saf/useSafsFilterOptions';
import type { TFiltersConfig } from '@purple/ui';

export const useSchoolSafFilterOptions = (schoolId: string) => {
  const { data, isLoading } = useSafsFilterOptions(schoolId);

  const filterConfig: TFiltersConfig = useMemo(() => {
    if (!data) {
      return { categories: [] };
    }

    return {
      categories: [
        {
          label: 'Needs',
          value: 'needs',
          filters: (data.needs as TNameableEntity[])?.map((need: TNameableEntity) => ({
            label: need.name,
            value: need.id,
          })),
        },
        {
          label: 'Priority',
          value: 'priority',
          filters: (data.priority as string[])?.map((priority: string) => ({
            label: snakeCaseToCapitalized(priority),
            value: priority,
          })),
        },
        {
          label: 'SAF Owner',
          value: 'saf_owner',
          filters: (data.saf_owner as TUserEntity[])?.map((owner: TUserEntity) => ({
            label: owner.full_name,
            value: owner.id,
          })),
        },
        {
          label: 'Status',
          value: 'status',
          filters: Object.values(SAF_STATUS).map((status) => ({
            label: snakeCaseToCapitalized(status),
            value: status,
          })),
        },
      ],
    };
  }, [data]);

  return {
    filterConfig,
    isLoading,
  };
};

import { getAxiosInstance } from '../../../api';
import { SCHOOLS_ENDPOINTS } from '../../../constants/private-api';
import type { TSchoolSafReportExportParameters } from '@purple/shared-types';

export const exportSchoolSafReport = async ({ schoolId, ...parameters }: TSchoolSafReportExportParameters) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<Blob>(SCHOOLS_ENDPOINTS.EXPORT_SHOOL_SAFS_REPORT(schoolId), {
    params: parameters,
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  });
  return response.data;
};

import { useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';
import { exportSubpopulationReport } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';

export const useExportSubpopulationReport = () => {
  return useMutation({
    mutationKey: [STUDENTS_QUERY_KEYS.EXPORT_SUBPOPULATION_REPORT],
    mutationFn: exportSubpopulationReport,
    onSuccess: (data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = `subpopulation-report (${format(new Date(), 'MM-dd-yyyy')}).xlsx`;
      document.body.append(link);
      link.click();
      link.remove();
      // Clean up the URL to prevent memory leaks
      window.URL.revokeObjectURL(url);
      showSuccessToast('System message', 'Table exported successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to export the table');
    },
  });
};

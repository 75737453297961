import { useTabs } from '@purple/hooks';
import { AppTabs } from '@purple/ui';
import { schoolTabs } from './school-tabs';

export const SchoolTabs: React.FC = () => {
  const { activeTab, tabChangeHandler, isReady } = useTabs({
    tabs: schoolTabs,
  });

  return <AppTabs className="col-span-2" tabs={schoolTabs} value={activeTab} onValueChange={tabChangeHandler} isReady={isReady} />;
};

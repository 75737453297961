import { Link } from 'react-router-dom';
import { formatDate, ReadableTakeActionType } from '@purple/shared-utils';
import { Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import type { ColumnDef } from '@tanstack/react-table';
import type { TSchoolActionListItem } from '@purple/shared-types';

export const schoolActionsReportColumns: ColumnDef<TSchoolActionListItem>[] = [
  {
    accessorKey: 'title',
    size: 280,
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600 uppercase">
        Name
      </Text>
    ),
    cell: ({ row }) =>
      row.original.title
        ? (
            <Tooltip>
              <TooltipTrigger asChild>
                <Link
                  to={AppRoutes.App.Actions.Details.makePath(row.original.id)}
                  title={row.original.title}
                  className="text-brand-blue-700 line-clamp-1 inline-flex break-all text-sm font-medium hover:underline hover:underline-offset-2"
                >
                  {row.original.title}
                </Link>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent withArrow={false}>
                  {row.original.title}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          )
        : (<span>—</span>),
  },
  {
    accessorKey: 'record_action_type',
    size: 250,
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600 uppercase">
        Action Type
      </Text>
    ),
    cell: ({ row: { original } }) => original.record_action_type
      ? (
          <Text variant="size-14" type="body-500" className="text-grey-950">
            {ReadableTakeActionType[original.record_action_type]}
          </Text>
        )
      : (
          <span>—</span>
        ),
  },
  {
    accessorKey: 'document_as',
    size: 150,
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600 min-w-max uppercase">
        Document As
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950 capitalize">
        {row.original.document_as}
      </Text>
    ),
  },
  {
    accessorKey: 'date_and_time',
    size: 140,
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600 uppercase">
        Date
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950 min-w-max">
        {formatDate(row.original.date_and_time)}
      </Text>
    ),
  },
  {
    accessorKey: 'saf',
    size: 180,
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600 uppercase">
        Linked Saf
      </Text>
    ),
    cell: ({ row: { original } }) => original.saf && original.saf.saf_number && original.saf.saf_number.length > 0
      ? (
          <Tooltip>
            <TooltipTrigger asChild>
              <Link
                to={AppRoutes.App.Safs.SAFDetails.makePath(original.saf.id)}
                title={original.saf.saf_number}
                className="text-brand-blue-700 line-clamp-1 inline-flex break-all text-sm font-medium hover:underline hover:underline-offset-2"
              >
                {original.saf.saf_number}
              </Link>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent withArrow={false}>
                {original.saf.saf_number}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        )
      : (
          <span>—</span>
        ),
  },
  {
    accessorKey: 'student',
    size: 200,
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600 uppercase">
        Owner
      </Text>
    ),
    cell: ({ row: { original } }) =>
      original.student && original.student.full_name && original.student.full_name.length > 0
        ? (
            <Tooltip>
              <TooltipTrigger asChild>
                <Link
                  to={AppRoutes.App.Students.StudentDetails.Root.makePath(original.student.id)}
                  title={original.student.full_name}
                  className="text-brand-blue-700 line-clamp-1 inline-flex break-all text-sm font-medium hover:underline hover:underline-offset-2"
                >
                  {original.student.full_name}
                </Link>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent withArrow={false}>
                  {original.student.full_name}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          )
        : (
            <span>—</span>
          ),
  },
];

import { getAxiosInstance } from '../../api';
import { STUDENTS_ENDPOINTS } from '../../constants/private-api';
import type { TStudentDataListRequestParameters } from '@purple/shared-types';

export const exportStudentDataList = async (parameters: TStudentDataListRequestParameters) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<Blob>(STUDENTS_ENDPOINTS.EXPORT_STUDENTS_DATA_LIST, {
    params: parameters,
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  });

  return response.data;
};

import { type FC, useCallback } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { usePaginate, useSearch, useTimePeriod } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { AppFilters, AppSelectedFiltersList, Button, cn, RadixSelect, RadixSelectContent, RadixSelectItem, RadixSelectTrigger, RadixSelectValue, SearchInput, TableFooterSection, Text } from '@purple/ui';
import { DataTable, DataTableSkeleton } from '~/components';
import { PERIOD_SELECT_OPTIONS, TIME_PERIODS } from '~/constants/options';
import { AppRoutes } from '~/constants/routes/routes';
import { useExportStudentDataList, useGetStudentsDataList } from '~/queries';
import { showErrorToast } from '~/shared/lib';
import { colorTrendsReportColumns } from './colorTrendsReportColumns';
import { colorTrendFiltersConfig } from './constants';

export const SchoolColorTrendReport: FC = () => {
  const { schoolId } = useParams();
  const [searchParameters] = useSearchParams();

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();
  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();
  const { onPeriodChange, onPeriodClear, timePeriod } = useTimePeriod({
    defaultValue: TIME_PERIODS.this_academic_year,
  });

  const { isLoading, data } = useGetStudentsDataList({
    limit,
    offset,
    search: debounceSearch,
    grade: searchParameters.get('grade'),
    current_color: searchParameters.get('current_color'),
    time_period: searchParameters.get('time_period'),
    school: schoolId,
  });

  const { mutate, isPending } = useExportStudentDataList();

  const exportColorTrendsReport = useCallback(() => {
    if (!schoolId) {
      return showErrorToast('Failed to export color trends report', 'School ID is missing');
    }
    mutate({
      school: schoolId,
      search,
      grade: searchParameters.get('grade'),
      current_color: searchParameters.get('current_color'),
      time_period: searchParameters.get('time_period'),
    });
  }, [mutate, schoolId, search, searchParameters]);

  return (
    <div className="flex w-full flex-col items-start justify-normal gap-6">
      <Button variant="link" className="h-auto p-0" iconLeft={<PurpleIcon name="chevron-left" />} asChild>
        <Link to={AppRoutes.App.School.Root.makePath(schoolId, 'overview')}>
          Back to School Profile
        </Link>
      </Button>
      <div className="border-grey-200 shadow-custom-heavy flex w-full flex-col gap-4 rounded-lg border bg-white">
        <div className="flex flex-col gap-4 px-4 pt-6">
          <Text variant="size-16" type="body-600">
            Color trends
          </Text>
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-4">
              <AppFilters config={colorTrendFiltersConfig} />
              <RadixSelect onValueChange={onPeriodChange} value={timePeriod}>
                <RadixSelectTrigger
                  hasClearButton={Boolean(timePeriod)}
                  onClearCallback={onPeriodClear}
                  triggerContainerClassName="w-[250px]"
                >
                  <RadixSelectValue placeholder="Time Period" />
                </RadixSelectTrigger>
                <RadixSelectContent>
                  {PERIOD_SELECT_OPTIONS.map(({ label, value }) => (
                    <RadixSelectItem key={value} value={value}>
                      {label}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
              <SearchInput
                value={search}
                onChange={onSearchChange}
                onClear={onClearSearch}
                placeholder="Search..."
                className="max-w-[250px]"
              />
            </div>
            <Button variant="primary" iconLeft={<PurpleIcon name={isPending ? 'loader' : 'download'} className={cn({ 'animate-spin': isPending })} />} onClick={exportColorTrendsReport} isLoading={isPending}>
              Export
            </Button>
          </div>
          <AppSelectedFiltersList config={colorTrendFiltersConfig} maxDisplayedFilters={10} />
        </div>
        <DataTable
          columns={colorTrendsReportColumns}
          data={data?.results || []}
          isLoading={isLoading}
          isFiltersApplied={Boolean(search)}
          emptyStateTitle="No students found"
          emptyStateMessage="There are no students to display. If it is an error, please contact support."
          skeleton={<DataTableSkeleton rows={limit} className="h-[58px]" />}
        />
        {data && (
          <TableFooterSection
            currentPage={page}
            pageCount={Math.ceil(data.count / limit)}
            onPageChange={onPageChange}
            rowsPerPage={limit}
            onRowsPerPageChange={onLimitChange}
            totalRows={data?.count || 0}
          />
        )}
      </div>
    </div>
  );
};

import { CYCLE_FLAGS_COLOR, type IStudentDataEntity } from '@purple/shared-types';
import { cutGradeName, getColorByRange } from '@purple/shared-utils';
import { Badge, Text } from '@purple/ui';
import { FlagBadge, LineChartCell, StudentCell } from '~/modules/Visualizer/StudentsData/components';
import type { ColumnDef } from '@tanstack/react-table';

export const colorTrendsReportColumns: ColumnDef<IStudentDataEntity>[] = [
  {
    accessorKey: 'student',
    size: 250,
    header: () => <Text variant="size-12" type="body-600" className="text-grey-600 uppercase">Student</Text>,
    cell: ({ row: { original } }) => (
      <StudentCell
        id={original.id}
        firstName={original.first_name}
        middleName={original.middle_name}
        lastName={original.last_name}
        avatar={original.photo}
        isTurnedRed={original.is_turned_red}
        subpop={original.subpop}
      />
    ),
  },
  {
    accessorKey: 'grade',
    size: 100,
    header: () => <Text variant="size-12" type="body-600" className="text-grey-600 text-center uppercase">Grade</Text>,
    cell: ({ row: { original } }) => original.grade && original.grade.length > 0 ? (<Text variant="size-14" type="body-500" className="text-grey-950 text-center">{cutGradeName(original.grade) }</Text>) : <span className="text-center">—</span>,
  },
  {
    accessorKey: 'absences',
    size: 100,
    header: () => <Text variant="size-12" type="body-600" className="text-grey-600 text-center uppercase">Absences</Text>,
    cell: ({ row: { original } }) => original.is_absences_red
      ? (
          <div className="flex items-center justify-center">
            <Badge
              label={original.total_absences.toString()}
              type="number"
              className="border-error-main text-error-main border bg-white"
            />
          </div>
        )
      : (
          <Text variant="size-14" type="body-500" className="text-grey-950 text-center">
            {original.total_absences}
          </Text>
        ),
  },
  {
    accessorKey: 'safs',
    size: 100,
    header: () => <Text variant="size-12" type="body-600" className="text-grey-600 text-center uppercase">SAFs</Text>,
    cell: ({ row: { original } }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950 text-center">
        {original.total_safs}
      </Text>
    ),
  },
  {
    accessorKey: 'actions',
    size: 100,
    header: () => <Text variant="size-12" type="body-600" className="text-grey-600 text-center uppercase">Actions</Text>,
    cell: ({ row: { original } }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950 text-center">
        {original.total_actions}
      </Text>
    ),
  },
  {
    accessorKey: 'previous_sy',
    size: 120,
    header: () => <Text variant="size-12" type="body-600" className="text-grey-600 text-center uppercase">Previous SY</Text>,
    cell: ({ row: { original } }) => {
      const badgeValue = original.previous_year_avg_flags_per_cycle ? original.previous_year_avg_flags_per_cycle : '—';
      const color = badgeValue === '—' ? CYCLE_FLAGS_COLOR.GREEN : getColorByRange(Number(badgeValue), original.colorsRange);
      return (
        <div className="flex justify-center">
          <FlagBadge color={color} value={badgeValue} />
        </div>
      );
    },
  },
  {
    accessorKey: 'current_sy',
    size: 120,
    header: () => <Text variant="size-12" type="body-600" className="text-grey-600 text-center uppercase">Current SY</Text>,
    cell: ({ row: { original } }) => {
      const badgeValue = original.current_year_avg_flags_per_cycle ? original.current_year_avg_flags_per_cycle : '—';
      const color = badgeValue === '—' ? CYCLE_FLAGS_COLOR.GREEN : getColorByRange(Number(badgeValue), original.colorsRange);
      return (
        <div className="flex justify-center">
          <FlagBadge color={color} value={badgeValue} />
        </div>
      );
    },
  },
  {
    accessorKey: 'color_trends',
    size: 350,
    header: () => <Text variant="size-12" type="body-600" className="text-grey-600 text-center uppercase">Color trends</Text>,
    cell: ({ row: { original } }) => (
      <div className="flex w-full items-center justify-center">
        <LineChartCell plot={original.plot} colorsRange={original.colorsRange} />
      </div>
    ),
  },
];

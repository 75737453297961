import { type FC, useCallback } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { usePaginate, useSearch, useSort } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { SORT_QUERY_NAME } from '@purple/shared-types';
import { AppFilters, AppSelectedFiltersList, Button, cn, RadixSelect, RadixSelectContent, RadixSelectItem, RadixSelectTrigger, RadixSelectValue, SearchInput, TableFooterSection, Text } from '@purple/ui';
import { DataTable, DataTableSkeleton } from '~/components';
import { AppRoutes } from '~/constants/routes/routes';
import { useExportActiveStudentReport, useStudentsReportData } from '~/queries';
import { showErrorToast } from '~/shared/lib';
import { activeStudentsReportColumns } from './activeStudentsReportColumns';
import { ACTIVE_STUDENTS_SORT_OPTIONS, activeStudentsFilterConfig } from './constants';

export const SchoolActiveStudentsReport: FC = () => {
  const { schoolId } = useParams();
  const [searchParameters] = useSearchParams();

  const { limit, offset, page, onLimitChange, onPageChange } = usePaginate();
  const { onSortChange, onSortClear, sort } = useSort();
  const { debounceSearch, onClearSearch, onSearchChange, search } = useSearch();

  const { data, isLoading } = useStudentsReportData({
    limit,
    offset,
    search: debounceSearch,
    grade: searchParameters.get('grade'),
    ethnicity: searchParameters.get('ethnicity'),
    ordering: searchParameters.get(SORT_QUERY_NAME),
    school: schoolId,
  });

  const { mutate, isPending } = useExportActiveStudentReport();

  const exportActiveStudentsReport = useCallback(() => {
    if (!schoolId) {
      return showErrorToast('System message', 'Unable to export report data: School ID is missing');
    }
    mutate({
      school: schoolId,
      search: debounceSearch,
      grade: searchParameters.get('grade'),
      ethnicity: searchParameters.get('ethnicity'),
      ordering: searchParameters.get(SORT_QUERY_NAME),
    });
  }, [mutate, schoolId, debounceSearch, searchParameters]);

  return (
    <div className="flex w-full flex-col items-start justify-normal gap-6">
      <Button variant="link" className="h-auto p-0" iconLeft={<PurpleIcon name="chevron-left" />} asChild>
        <Link to={AppRoutes.App.School.Root.makePath(schoolId, 'overview')}>
          Back to School Profile
        </Link>
      </Button>
      <div className="border-grey-200 shadow-custom-heavy flex w-full flex-col gap-4 rounded-lg border bg-white">
        <div className="flex flex-col gap-4 px-4 pt-6">
          <Text variant="size-16" type="body-600">
            Active students
          </Text>
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-4">
              <AppFilters config={activeStudentsFilterConfig} />
              <RadixSelect value={sort} defaultValue={sort} onValueChange={onSortChange}>
                <RadixSelectTrigger
                  hasClearButton={!!sort}
                  onClearCallback={onSortClear}
                  triggerContainerClassName="w-[250px]"
                >
                  <RadixSelectValue placeholder="Sort" />
                </RadixSelectTrigger>
                <RadixSelectContent className="min-w-max">
                  {ACTIVE_STUDENTS_SORT_OPTIONS.map((option) => (
                    <RadixSelectItem key={option.value} value={option.value} className="cursor-pointer">
                      {option.label}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
              <SearchInput
                value={search}
                onChange={onSearchChange}
                onClear={onClearSearch}
                placeholder="Search..."
                className="max-w-[250px]"
              />
            </div>
            <Button variant="primary" iconLeft={<PurpleIcon name={isPending ? 'loader' : 'download'} className={cn({ 'animate-spin': isPending })} />} onClick={exportActiveStudentsReport} isLoading={isPending}>
              Export
            </Button>
          </div>
          <AppSelectedFiltersList config={activeStudentsFilterConfig} maxDisplayedFilters={10} />
        </div>
        <DataTable
          columns={activeStudentsReportColumns}
          data={data?.results || []}
          isLoading={isLoading}
          isFiltersApplied={Boolean(search)}
          emptyStateTitle="No students found"
          emptyStateMessage="There are no students to display. If it is an error, please contact support."
          skeleton={<DataTableSkeleton rows={limit} />}
        />
        {data && (
          <TableFooterSection
            currentPage={page}
            pageCount={Math.ceil(data.count / limit)}
            onPageChange={onPageChange}
            rowsPerPage={limit}
            onRowsPerPageChange={onLimitChange}
            totalRows={data?.count || 0}
          />
        )}
      </div>
    </div>
  );
};

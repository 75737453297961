export const SUBPOPULATION_SORT_OPTIONS = [
  {
    label: 'Total Actions(ASC)',
    value: 'total_actions',
  },
  {
    label: 'Total Actions(DESC)',
    value: '-total_actions',
  },
  {
    label: 'Total SAFs(ASC)',
    value: 'total_safs',
  },
  {
    label: 'Grade(ASC)',
    value: 'grade',
  },
  {
    label: 'Grade(DESC)',
    value: '-grade',
  },
  {
    label: 'Total SAFs(DESC)',
    value: '-total_safs',
  },
  {
    label: ' Student Name(ASC)',
    value: 'last_name',
  },
  {
    label: 'Student Name(DESC)',
    value: '-last_name',
  },
];

/* eslint-disable perfectionist/sort-imports */
// Sentry initialization should be imported first!
import './sentry.client.config';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { PermissionsProvider } from '@purple/permissions';
import { AppLoaderScreen } from '@purple/ui';
// layouts
import { AuthLayout, FormLayout, SiteLayout } from './layout';
// pages
import { NewPassword, ResetPassword, SignIn, SsoComplete } from './pages/Auth';
import { ConfirmEmail } from './pages/ConfirmEmail';
import { DeclineEmail } from './pages/DeclineEmail';
import { StudentAssistanceFormPage } from './pages/Form';
import {
  ActionDetailsPage,
  ActionGroupDetailsPage,
  AttendanceImpactAnalysis,
  AttendanceTrendsReportPage,
  BehaviorAnalysisReportPage,
  CommunityActivityDetailsPage,
  CommunityPage,
  DistrictSettingsPage,
  GradeReport,
  HelpPage,
  HomePage,
  MonitorPage,
  MyGroupActionsPage,
  NewStudentsReport,
  ProfilePage,
  SafDetails,
  SafListManagerPage,
  SafSubmittersReport,
  School,
  ServiceLinkManagerPage,
  ServiceProvidersPage,
  StudentProfilePage,
  StudentsVisualizerPage,
  SupportByNeeds,
  SupportByProgram,
  SupportByRole,
  SupportByType,
  TotalFlagsReportPage,
} from './pages/Main';
import NotFound from './pages/NotFound';
import ServerError from './pages/ServerError';
// constants
import { AppRoutes } from './constants/routes/routes';
// hooks
import { useAppInit } from './hooks/useAppInit';
// Internationalization
import './i18n/config';
import type { FC } from 'react';
import { Print } from './pages/System';
import DeactivatedDistrict from './pages/DeactivatedDistrict';
import { useAppSelector } from './hooks';
import { isLoadingSelector } from './store/features/user';
import { SchoolActionsReport, SchoolActiveStudentsReport, SchoolColorTrendReport, SchoolProgramsReport, SchoolSafReport, SchoolSubpopulationsReport } from './modules/Schools';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const PurpleApplicationRoutes: FC = () => {
  const { isLoading, userPermissions } = useAppInit();

  const isAuthLoading = useAppSelector(isLoadingSelector);

  if (isLoading || isAuthLoading) return <AppLoaderScreen />;

  return (
    <PermissionsProvider userPermissions={userPermissions || []}>
      <SentryRoutes>
        {/* AUTH APP ROUTES */}
        <Route path="/auth" element={<AuthLayout />}>
          <Route index element={<Navigate replace to={AppRoutes.Auth.SignIn.Root.path} />} />
          <Route path={AppRoutes.Auth.SignIn.Root.path} element={<SignIn />} />
          <Route path={AppRoutes.Auth.ResetPassword.Root.path} element={<ResetPassword />} />
          <Route path={AppRoutes.Auth.NewPassword.Root.path} element={<NewPassword />} />
          <Route path={AppRoutes.Auth.SsoComplete.Root.path} element={<SsoComplete />} />
        </Route>

        {/* MAIN APP ROUTS */}
        <Route element={<SiteLayout />}>
          <Route index path={AppRoutes.App.Home.Root.path} element={<HomePage />} />
          <Route index path={AppRoutes.App.NewStudentsReport.Root.path} element={<NewStudentsReport />} />
          <Route index path={AppRoutes.App.GradeReport.Root.path} element={<GradeReport />} />
          <Route index path={AppRoutes.App.SafSubmittersReport.Root.path} element={<SafSubmittersReport />} />
          <Route path={AppRoutes.App.Visualizer.Root.path} element={<StudentsVisualizerPage />} />
          {/* SAF ROUTES */}
          <Route path={AppRoutes.App.Safs.Root.path} element={<Outlet />}>
            <Route index element={<SafListManagerPage />} />
            <Route path={AppRoutes.App.Safs.SAFDetails.path} element={<SafDetails />} />
          </Route>
          <Route path={AppRoutes.App.ServiceLinks.Root.path} element={<ServiceLinkManagerPage />} />
          <Route path={AppRoutes.App.Profile.Root.path} element={<ProfilePage />} />
          <Route path={AppRoutes.App.Monitor.Root.path} element={<MonitorPage />} />
          <Route path={AppRoutes.App.ServiceProviders.Root.path} element={<ServiceProvidersPage />} />
          <Route path={AppRoutes.App.Help.Root.path} element={<HelpPage />} />
          {/* COMMUNITY ACTIVITY ROUTES  */}
          <Route path={AppRoutes.App.Community.Root.path} element={<Outlet />}>
            <Route index element={<CommunityPage />} />
            <Route path={AppRoutes.App.Community.Details.path} element={<CommunityActivityDetailsPage />} />
          </Route>
          {/* MY SCHOOL */}
          <Route path={AppRoutes.App.School.Root.path} element={<Outlet />}>
            <Route index element={<School />} />
            <Route path={AppRoutes.App.School.SchoolSafReport.path} element={<SchoolSafReport />} />
            <Route path={AppRoutes.App.School.SchoolActionsReport.path} element={<SchoolActionsReport />} />
            <Route path={AppRoutes.App.School.SchoolProgramsReport.path} element={<SchoolProgramsReport />} />
            <Route path={AppRoutes.App.School.SchoolActiveStudentsReport.path} element={<SchoolActiveStudentsReport />} />
            <Route path={AppRoutes.App.School.SchoolColorTrendReport.path} element={<SchoolColorTrendReport />} />
            <Route path={AppRoutes.App.School.SchoolSubpopulationsReport.path} element={<SchoolSubpopulationsReport />} />
          </Route>
          {/* ACTIONS */}
          <Route path={AppRoutes.App.Actions.Root.path} element={<Outlet />}>
            <Route index element={<Navigate replace to={AppRoutes.App.Visualizer.Root.path} />} />
            <Route path={AppRoutes.App.Actions.Details.path} element={<ActionDetailsPage />} />
            <Route path={AppRoutes.App.Actions.Group.path} element={<MyGroupActionsPage />} />
            <Route path={AppRoutes.App.Actions.GroupDetails.path} element={<ActionGroupDetailsPage />} />
          </Route>
          {/* DISTRICT ROUTES */}
          <Route path={AppRoutes.App.District.Root.path} element={<Outlet />}>
            <Route index element={<DistrictSettingsPage />} />
            <Route path={AppRoutes.App.District.Settings.path} element={<DistrictSettingsPage />} />
          </Route>
          {/* STUDENTS ROUTES */}
          <Route path={AppRoutes.App.Students.Root.path} element={<Outlet />}>
            <Route index element={<Navigate replace to={AppRoutes.App.Visualizer.Root.path} />} />
            {/* STUDENT PROFILE ROUTES */}
            <Route path={AppRoutes.App.Students.StudentDetails.Root.path} element={<Outlet />}>
              <Route index element={<StudentProfilePage />} />
              <Route
                path={AppRoutes.App.Students.StudentDetails.AttendanceImpactAnalysis.path}
                element={<AttendanceImpactAnalysis />}
              />
              <Route path={AppRoutes.App.Students.StudentDetails.SupportByRole.path} element={<SupportByRole />} />
              <Route path={AppRoutes.App.Students.StudentDetails.SupportByType.path} element={<SupportByType />} />
              <Route
                path={AppRoutes.App.Students.StudentDetails.SupportByProgram.path}
                element={<SupportByProgram />}
              />
              <Route path={AppRoutes.App.Students.StudentDetails.SupportByNeeds.path} element={<SupportByNeeds />} />
              <Route path={AppRoutes.App.Students.StudentDetails.TotalFlagsReport.path} element={<TotalFlagsReportPage />} />
              <Route path={AppRoutes.App.Students.StudentDetails.AttendanceTrendsReport.path} element={<AttendanceTrendsReportPage />} />
              <Route path={AppRoutes.App.Students.StudentDetails.BehaviorAnalysisReport.path} element={<BehaviorAnalysisReportPage />} />
            </Route>
          </Route>
        </Route>
        {/* FORM ROUTES */}
        <Route path={AppRoutes.Form.Root.path} element={<FormLayout />}>
          <Route index element={<Navigate replace to={AppRoutes.Form.StudentAssistance.Root.path} />} />
          <Route path={AppRoutes.Form.StudentAssistance.Root.path} element={<StudentAssistanceFormPage />} />
        </Route>
        {/* EMAIL CONFIRM PAGE */}
        <Route path={AppRoutes.System.ConfirmEmail.Root.path} element={<ConfirmEmail />} />
        {/* EMAIL DECLINE PAGE */}
        <Route path={AppRoutes.System.DeclineEmail.Root.path} element={<DeclineEmail />} />
        {/* SERVER ERROR PAGE */}
        <Route path={AppRoutes.System.ServerError.Root.path} element={<ServerError />} />
        {/* DEACTIVATED DISTRICT */}
        <Route path={AppRoutes.System.DeactivatedDistrict.Root.path} element={<DeactivatedDistrict />} />
        {/* NOT FOUND PAGE */}
        <Route path="*" element={<NotFound />} />
      </SentryRoutes>
    </PermissionsProvider>
  );
};

const PurpleTechnicalRoutes: FC = () => {
  return (
    <Routes>
      <Route path={AppRoutes.System.Print.path} element={<Outlet />}>
        <Route index element={<Print />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export const PurpleApplication: FC = () => {
  const { pathname } = useLocation();
  return (
    AppRoutes.System.Print.path === pathname ? <PurpleTechnicalRoutes /> : <PurpleApplicationRoutes />
  );
};

import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Card,
  CardContent,
  CardTitle,
  cn,
  ReChartContainer,
  ReChartTooltip,
  ReChartTooltipContent,
  type TChartConfig,
} from '@purple/ui';
import { BarChartSkeleton } from './BarChartSkeleton';
import type { FC } from 'react';

type TBarChartCardProperties = {
  cardTitle: string;
  onDownload: () => void;
  chartConfig: TChartConfig;
  chartData: { month: string; value: number }[];
  isLoading?: boolean;
  className?: string;
};

export const BarChartCard: FC<TBarChartCardProperties> = (props) => {
  const { cardTitle, onDownload, chartConfig, chartData, isLoading, className } = props;

  if (isLoading) {
    return <BarChartSkeleton />;
  }

  return (
    <Card className={cn('gap-6 px-4 py-6 shadow-custom-medium', className)}>
      <div className="flex items-center justify-between gap-2">
        <CardTitle className="text-base font-semibold">{cardTitle}</CardTitle>
        <Button variant="tertiary_icon_only" size="icon_32" onClick={onDownload} data-type="download-button">
          <PurpleIcon name="download" />
        </Button>
      </div>
      <CardContent className="flex items-center gap-8">
        <ReChartContainer config={chartConfig} className="h-[250px] w-full">
          <BarChart accessibilityLayer data={chartData} barSize={55}>
            <CartesianGrid vertical={false} strokeDasharray="3 3" strokeWidth={1} shapeRendering="crispEdges" />
            <ReChartTooltip cursor={false} content={<ReChartTooltipContent labelKey="month" nameKey="month" />} />
            <XAxis
              dataKey="month"
              tickLine={false}
              tickMargin={5}
              axisLine={false}
              tickFormatter={(value: string) => value.slice(0, 3)}
              className="font-primary uppercase"
              tick={{ fontSize: 10, fontWeight: 500, textAnchor: 'middle' }}
              label={{
                value: 'Month',
                position: 'left',
                className: 'fill-grey-600 normal-case',
                fontSize: 10,
                dx: 10,
                dy: -2,
                lineHeight: 12,
                fontWeight: 400,
              }}
            />
            <YAxis
              dataKey="value"
              width={40}
              tickSize={40}
              tickCount={5}
              interval={0}
              className="font-primary"
              tick={{ fontSize: 10, fontWeight: 500, textAnchor: 'left' }}
              tickLine={false}
              axisLine={false}
            />
            <Bar dataKey="value" radius={8} />
          </BarChart>
        </ReChartContainer>
      </CardContent>
    </Card>
  );
};

import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import type { ColumnDef } from '@tanstack/react-table';
import type { TSchoolActionListItem } from '@purple/shared-types';

export const schoolActionsColumns: ColumnDef<TSchoolActionListItem>[] = [
  {
    accessorKey: 'title',
    size: 320,
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Name
      </Text>
    ),
    cell: ({ row }) =>
      row.original.title
        ? (
            <Tooltip>
              <TooltipTrigger asChild>
                <Link
                  to={AppRoutes.App.Actions.Details.makePath(row.original.id)}
                  title={row.original.title}
                  className="line-clamp-1 inline-flex break-all text-sm font-medium text-brand-blue-700 hover:underline hover:underline-offset-2"
                >
                  {row.original.title}
                </Link>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent withArrow={false}>
                  {row.original.title}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          )
        : (<span>—</span>),
  },
  {
    accessorKey: 'document_as',
    size: 120,
    header: () => (
      <Text variant="size-12" type="body-600" className="min-w-max uppercase text-grey-600">
        Document As
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="capitalize text-grey-950">
        {row.original.document_as}
      </Text>
    ),
  },
  {
    accessorKey: 'date_and_time',
    size: 140,
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Date
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="min-w-max text-grey-950">
        {format(row.original.date_and_time, 'MMMM d, yyyy')}
      </Text>
    ),
  },
  {
    accessorKey: 'created_by',
    size: 160,
    header: () => (
      <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
        Owner
      </Text>
    ),
    cell: ({ row: { original } }) =>
      original.created_by && original.created_by.full_name.length > 0
        ? (
            <Tooltip>
              <TooltipTrigger asChild>
                <Text variant="size-14" type="body-500" className="line-clamp-1 break-all">
                  {original.created_by.full_name}
                </Text>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent withArrow={false}>
                  {original.created_by.full_name}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          )
        : (
            <span>—</span>
          ),
  },
];

import { type FC, useCallback, useMemo } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { usePaginate, useSearch, useTimePeriod } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { SORT_QUERY_NAME } from '@purple/shared-types';
import { AppFilters, AppSelectedFiltersList, Button, cn, RadixSelect, RadixSelectContent, RadixSelectItem, RadixSelectTrigger, RadixSelectValue, SearchInput, TableFooterSection, Text } from '@purple/ui';
import { DataTable, DataTableSkeleton } from '~/components';
import { PERIOD_SELECT_OPTIONS, TIME_PERIODS } from '~/constants/options';
import { AppRoutes } from '~/constants/routes/routes';
import { useExportSchoolActionsReport, useSchoolActionsList } from '~/queries/schools/actions';
import { showErrorToast } from '~/shared/lib';
import { schoolActionsFilterOptions } from './constants';
import { schoolActionsReportColumns } from './schoolActionsReportColumns';
import type { TSchoolActionsRequestParameters } from '@purple/shared-types';

export const SchoolActionsReport: FC = () => {
  const [searchParameters] = useSearchParams();
  const { schoolId } = useParams();

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();
  const { onPeriodChange, onPeriodClear, timePeriod } = useTimePeriod({
    defaultValue: TIME_PERIODS.this_academic_year,
  });
  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();

  const schoolActionsRequestParameters: TSchoolActionsRequestParameters = useMemo(() => ({
    ordering: searchParameters.get(SORT_QUERY_NAME),
    search: debounceSearch,
    document_as: searchParameters.get('document_as'),
    record_action_type: searchParameters.get('record_action_type'),
    time_period: timePeriod,
  }), [debounceSearch, searchParameters, timePeriod]);

  const { mutate, isPending } = useExportSchoolActionsReport();

  const { data, isLoading } = useSchoolActionsList({
    schoolId: schoolId as string,
    queryParameters: {
      limit,
      offset,
      ...schoolActionsRequestParameters,
    },
  });

  const exportSchoolActionReport = useCallback(() => {
    if (!schoolId) {
      return showErrorToast('System message', `Failed to export the table for school with id: ${schoolId}`);
    }
    mutate({ schoolId, ...schoolActionsRequestParameters });
  }, [mutate, schoolActionsRequestParameters, schoolId]);

  return (
    <div className="flex w-full flex-col items-start justify-normal gap-6">
      <Button variant="link" className="h-auto p-0" iconLeft={<PurpleIcon name="chevron-left" />} asChild>
        <Link to={AppRoutes.App.School.Root.makePath(schoolId, 'overview')}>
          Back to School Profile
        </Link>
      </Button>
      <div className="flex w-full flex-col gap-4 rounded-lg border border-grey-200 bg-white shadow-custom-heavy">
        <div className="flex flex-col gap-4 px-4 pt-6">
          <Text variant="size-16" type="body-600">
            Actions
          </Text>
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-4">
              <AppFilters config={schoolActionsFilterOptions} />
              <RadixSelect onValueChange={onPeriodChange} value={timePeriod}>
                <RadixSelectTrigger
                  hasClearButton={Boolean(timePeriod)}
                  onClearCallback={onPeriodClear}
                  triggerContainerClassName="w-[250px]"
                >
                  <RadixSelectValue placeholder="Time Period" />
                </RadixSelectTrigger>
                <RadixSelectContent>
                  {PERIOD_SELECT_OPTIONS.map(({ label, value }) => (
                    <RadixSelectItem key={value} value={value}>
                      {label}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
              <SearchInput
                value={search}
                onChange={onSearchChange}
                onClear={onClearSearch}
                placeholder="Search..."
                className="max-w-[250px]"
              />
            </div>
            <Button variant="primary" iconLeft={<PurpleIcon name={isPending ? 'loader' : 'download'} className={cn({ 'animate-spin': isPending })} />} onClick={exportSchoolActionReport} isLoading={isPending}>
              Export
            </Button>
          </div>
          <AppSelectedFiltersList config={schoolActionsFilterOptions} maxDisplayedFilters={10} />
        </div>
        <DataTable
          columns={schoolActionsReportColumns}
          data={data?.results || []}
          isLoading={isLoading}
          isFiltersApplied={Boolean(search)}
          emptyStateTitle="No Actions found"
          emptyStateMessage="There are no Actions to display. If it an error, please contact support."
          skeleton={<DataTableSkeleton rows={limit} />}
        />
        {data && (
          <TableFooterSection
            currentPage={page}
            pageCount={Math.ceil(data.count / limit)}
            onPageChange={onPageChange}
            rowsPerPage={limit}
            onRowsPerPageChange={onLimitChange}
            totalRows={data?.count || 0}
          />
        )}
      </div>
    </div>
  );
};

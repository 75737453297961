import { type FC, memo } from 'react';
import { cutGradeName } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import { ReadableTimePeriods, TIME_PERIODS } from '~/constants/options';
import type { IStudentDetailsResponse } from '@purple/shared-services';
import type { TTimePeriod } from '~/constants/options';

type TStudentDetailsSectionProps = {
  studentDetailsData: IStudentDetailsResponse;
  timePeriod?: TTimePeriod;
};

export const StudentDetailsSection: FC<TStudentDetailsSectionProps> = memo(({ studentDetailsData, timePeriod = TIME_PERIODS.this_academic_year }) => {
  return (
    <section className="flex flex-col gap-2.5 border-b border-grey-200 pb-2.5">
      <div className="flex items-center justify-between gap-2">
        <Text variant="size-12" type="body-600" className="text-grey-600">
          Student Profile:
          {' '}
          <span className="text-grey-title">{studentDetailsData.details.full_name}</span>
        </Text>
        <Text variant="size-12" type="body-500" className="text-grey-600">
          Time Period:
          {' '}
          <span className="text-grey-title">{ReadableTimePeriods[timePeriod]}</span>
        </Text>
      </div>
      <div className="flex flex-col gap-0.5">
        <div className="flex items-center gap-9">
          <Text variant="size-12" type="body-400" className="w-[90px] text-grey-400">Student ID</Text>
          <Text variant="size-12" type="body-400">{studentDetailsData.details.student_id ?? '—'}</Text>
        </div>
        <div className="flex items-center gap-9">
          <Text variant="size-12" type="body-400" className="w-[90px] text-grey-400">Student Grade</Text>
          <Text variant="size-12" type="body-400">{studentDetailsData.details.grade && studentDetailsData.details.grade.length > 0 ? cutGradeName(studentDetailsData.details.grade) : '—'}</Text>
        </div>
        <div className="flex items-center gap-9">
          <Text variant="size-12" type="body-400" className="w-[90px] text-grey-400">Current School</Text>
          <Text variant="size-12" type="body-400">
            {studentDetailsData.details.school && studentDetailsData.details.school.name
              ? studentDetailsData.details.school.name
              : '—'}
          </Text>
        </div>
      </div>
    </section>
  );
});

import { memo } from 'react';
import { useParams } from 'react-router-dom';
import { useSearch, useSort } from '@purple/hooks';
import {
  AppFilters,
  AppSelectedFiltersList,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  SearchInput,
} from '@purple/ui';
import { PERIOD_SELECT_OPTIONS } from '~/constants/options';
import { useSchoolSafFilterOptions } from '~/hooks/useSchoolSafFilterOptions';
import { SAFS_SORT_SELECT_OPTIONS } from '../constants';

type TSaFsHeaderSectionProperties = {
  timePeriod: string;
  onPeriodChange: (newPeriod: string) => void;
  onPeriodClear: () => void;
};

export const SaFsHeaderSection = memo(({
  timePeriod,
  onPeriodChange,
  onPeriodClear,
}: TSaFsHeaderSectionProperties) => {
  const { schoolId } = useParams();
  const { sort, onSortChange, onSortClear } = useSort();
  const { search, onSearchChange, onClearSearch } = useSearch();

  const { filterConfig, isLoading } = useSchoolSafFilterOptions(schoolId as string);

  return (
    <div className="flex w-full flex-col gap-4 px-4">
      <div className="flex w-full flex-wrap justify-between gap-2">
        <div className="flex gap-4">
          <AppFilters config={filterConfig} loading={isLoading} />
          <RadixSelect value={sort} defaultValue={sort} onValueChange={onSortChange}>
            <RadixSelectTrigger
              hasClearButton={!!sort}
              onClearCallback={onSortClear}
              triggerContainerClassName="w-[200px]"
            >
              <RadixSelectValue placeholder="Sort" />
            </RadixSelectTrigger>
            <RadixSelectContent className="w-max">
              {SAFS_SORT_SELECT_OPTIONS.map((option) => (
                <RadixSelectItem key={option.value} value={option.value} className="cursor-pointer">
                  {option.label}
                </RadixSelectItem>
              ))}
            </RadixSelectContent>
          </RadixSelect>
          <RadixSelect onValueChange={onPeriodChange} value={timePeriod}>
            <RadixSelectTrigger
              hasClearButton={Boolean(timePeriod)}
              onClearCallback={onPeriodClear}
              triggerContainerClassName="w-[200px]"
            >
              <RadixSelectValue placeholder="Time Period" />
            </RadixSelectTrigger>
            <RadixSelectContent>
              {PERIOD_SELECT_OPTIONS.map(({ label, value }) => (
                <RadixSelectItem key={value} value={value}>
                  {label}
                </RadixSelectItem>
              ))}
            </RadixSelectContent>
          </RadixSelect>
        </div>
        <SearchInput
          value={search}
          onChange={onSearchChange}
          onClear={onClearSearch}
          placeholder="Search..."
          className="max-w-[200px]"
        />
      </div>
      <AppSelectedFiltersList config={filterConfig} maxDisplayedFilters={8} />
    </div>
  );
});

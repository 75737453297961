import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePaginate, useSearch, useTimePeriod } from '@purple/hooks';
import { SORT_QUERY_NAME } from '@purple/shared-types';
import { convertToFilterConfig, SAF_QUERY_KEYS } from '@purple/shared-utils';
import { AppSelectedFiltersList, Heading, TableFooterSection } from '@purple/ui';
import {
  CloseSafModal,
  DataTable,
  DataTableSkeleton,
  SafNoNeedModal,
  SafPlaceOnHoldModal,
  SafRemoveFromHoldModal,
  SafTransferModal,
  SafUnidentifiableModal,
  TakeActionModal,
} from '~/components';
import { ModalType } from '~/constants/modals';
import { TIME_PERIODS } from '~/constants/options';
import { queryClient } from '~/constants/query-client';
import { useAppSelector } from '~/hooks';
import { useSafListManager, useSafListManagerFilters } from '~/queries';
import { userSelector } from '~/store/features/user';
import { ActionsHeaderSection, NewEmailDialog, SafListTotalCards } from './components';
import { STATIC_SAF_FILTERS } from './constants';
import { useSafListColumns } from './useSafListColumns';

const SafListManager = () => {
  const { id: userId } = useAppSelector(userSelector);

  const [selectedSafId, setSelectedSafId] = useState<string>('');

  const [searchParameters] = useSearchParams();

  const selectedSafIdChangeHandler = (safId: string) => {
    setSelectedSafId(safId);
  };

  const resetSelectedSafIdHandler = () => {
    setSelectedSafId('');
  };

  const afterCloseSafSuccessHandler = () => {
    resetSelectedSafIdHandler();
    queryClient.invalidateQueries({ queryKey: [SAF_QUERY_KEYS.GET_SAF_LIST_MANAGER] });
  };

  const { debounceSearch, search } = useSearch();

  const sort = searchParameters.get(SORT_QUERY_NAME) || '';
  const status = searchParameters.get('status') || '';
  const priority = searchParameters.get('priority') || '';
  const current_owner = searchParameters.get('current_owner');

  const { onPeriodChange, onPeriodClear, timePeriod } = useTimePeriod({
    defaultValue: TIME_PERIODS.this_academic_year,
  });
  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();

  const { data: filtersData, isLoading: isFiltersDataLoading } = useSafListManagerFilters(userId);

  const { data, isLoading } = useSafListManager({
    limit,
    offset,
    ordering: sort,
    search: debounceSearch,
    time_period: timePeriod,
    status,
    priority,
    current_owner: current_owner || '',
  });

  const isDataNotExist = useMemo(
    () => !data?.results || (data.results.length === 0 && !isLoading),
    [data?.results, isLoading],
  );

  const pageCount = useMemo(() => {
    return data?.count ? Math.ceil(data.count / limit) : 1;
  }, [data?.count, limit]);

  const {
    new: totalNew,
    prioritized: totalPrioritized,
    on_hold: totalOnHold,
    closed: totalClosed,
  } = useMemo(() => {
    if (data) {
      return data.totals;
    }
    return {
      new: '-',
      prioritized: '-',
      on_hold: '-',
      closed: '-',
    };
  }, [data]);

  const filterOptions = useMemo(() => {
    const fetchedFiltersOptions = filtersData
      ? convertToFilterConfig(filtersData)
      : {
          categories: [],
        };

    return { categories: [...fetchedFiltersOptions.categories, ...STATIC_SAF_FILTERS.categories] };
  }, [filtersData]);

  const columns = useSafListColumns({
    onSafIdChange: selectedSafIdChangeHandler,
  });

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-4 px-4 py-6">
        <Heading variant="size-22" type="heading-600" tag="h3" className="text-grey-title">
          SAF List
        </Heading>
        <SafListTotalCards
          totalNew={totalNew}
          totalPrioritized={totalPrioritized}
          totalOnHold={totalOnHold}
          totalClosed={totalClosed}
          isLoading={isLoading}
        />
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col gap-5 px-4">
          <ActionsHeaderSection
            isLoading={isFiltersDataLoading}
            filtersConfig={filterOptions}
            timePeriod={timePeriod}
            onPeriodChange={onPeriodChange}
            onPeriodClear={onPeriodClear}
          />
          <AppSelectedFiltersList config={filterOptions} maxDisplayedFilters={16} />
        </div>
        <div className="flex flex-col gap-5">
          <div>
            <DataTable
              columns={columns}
              data={data?.results || []}
              isLoading={isLoading}
              isFiltersApplied={Boolean(search)}
              emptyStateTitle="No SAFs found"
              emptyStateMessage="There are no SAFs to display. If it an error, please contact support."
              skeleton={<DataTableSkeleton rows={limit} />}
            />
            {!isDataNotExist && (
              <TableFooterSection
                currentPage={page}
                pageCount={pageCount}
                onPageChange={onPageChange}
                rowsPerPage={limit}
                onRowsPerPageChange={onLimitChange}
                totalRows={data?.count || 0}
              />
            )}
          </div>
        </div>
      </div>
      <SafPlaceOnHoldModal safId={selectedSafId} onCloseCallBack={resetSelectedSafIdHandler} />
      <SafRemoveFromHoldModal safId={selectedSafId} onCloseCallBack={resetSelectedSafIdHandler} />
      <SafTransferModal safId={selectedSafId} onCloseCallBack={resetSelectedSafIdHandler} />
      <SafNoNeedModal safId={selectedSafId} onCloseCallBack={resetSelectedSafIdHandler} />
      <SafUnidentifiableModal safId={selectedSafId} onCloseCallBack={resetSelectedSafIdHandler} />
      <TakeActionModal />
      <CloseSafModal safId={selectedSafId as string} onSuccess={afterCloseSafSuccessHandler} />
      <NewEmailDialog modalName={ModalType.SAF_NEW_EMAIL} showSubject defaultSafId={selectedSafId as string} />
    </div>
  );
};

export { SafListManager };

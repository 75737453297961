import { GRADE_CHOICES } from '@purple/shared-types';
import { cutGradeName, STUDENT_ETHNICITY } from '@purple/shared-utils';
import type { TFiltersConfig } from '@purple/ui';

export const activeStudentsFilterConfig: TFiltersConfig = {
  categories: [
    {
      label: 'Grade',
      value: 'grade',
      filters: Object.values(GRADE_CHOICES).map((grade) => ({
        label: cutGradeName(grade),
        value: grade,
      })),
    },
    {
      label: 'Ethnicity',
      value: 'ethnicity',
      filters: Object.values(STUDENT_ETHNICITY).map((item) => ({
        label: item,
        value: item,
      })),
    },
  ],
};

export const ACTIVE_STUDENTS_SORT_OPTIONS = [
  {
    label: 'Total Actions(ASC)',
    value: 'total_actions',
  },
  {
    label: 'Total Actions(DESC)',
    value: '-total_actions',
  },
  {
    label: 'Total SAFs(ASC)',
    value: 'total_safs',
  },
  {
    label: 'Total SAFs(DESC)',
    value: '-total_safs',
  },
  {
    label: ' Student Name(ASC)',
    value: 'last_name',
  },
  {
    label: 'Student Name(DESC)',
    value: '-last_name',
  },
];

import { useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';
import { exportSchoolActionsReport } from '@purple/shared-services';
import { SCHOOLS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/shared/lib';

export const useExportSchoolActionsReport = () => {
  return useMutation({
    mutationKey: [SCHOOLS_QUERY_KEYS.EXPORT_SCHOOL_ACTIONS_REPORT],
    mutationFn: exportSchoolActionsReport,
    onSuccess: (response) => {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = `school-actions-report-(${format(new Date(), 'MM.dd.yyyy')}).xlsx`;
      document.body.append(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      showSuccessToast('System message', 'Table exported successfully. Check your default browser downloads folder');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to export the table');
    },
  });
};

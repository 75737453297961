import { useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Placeholder } from '@tiptap/extension-placeholder';
import { TextAlign } from '@tiptap/extension-text-align';
import { Typography } from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { z } from 'zod';
import { useQueryParameter } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { ACCEPTED_EMAIL_FILE_TYPES, MAX_EMAIL_FILES_SIZE } from '@purple/shared-utils';
import {
  Button,
  cn,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  FileItem,
  FileList,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  ScrollArea,
  Separator,
  Text,
} from '@purple/ui';
import { LinkBubbleMenu, SafLinkExtention } from '~/components';
import { useModal } from '~/hooks';
import { SafEmailQueryName } from '~/modules/SafListManager/SafDetailsPage/SafDetailsTabs/Tabs/Emails/constants';
import { useReplySafEmail, useSendSafEmail } from '~/queries';
import { showErrorToast } from '~/shared/lib';
import { EditorToolbar } from './EditorToolbar';
import type React from 'react';
import type { ModalType } from '~/constants/modals';

const addEmailSchema = z
  .object({
    subject: z.string().trim().max(78, 'Email subject should not exceed 78 characters'),
    text: z.string().trim().min(1, 'Email text is required'),
    files: z
      .array(z.custom<File>((value) => value instanceof File, { message: 'Invalid file type' }))
      .refine((files) => files.reduce((accumulator, file) => accumulator + file.size, 0) <= MAX_EMAIL_FILES_SIZE, {
        message: `Total file size should not exceed ${MAX_EMAIL_FILES_SIZE / 1024 / 1024} MB`,
      })
      .refine((files) => files.every((file) => ACCEPTED_EMAIL_FILE_TYPES.has(file.type)), {
        message: 'Only images, .pdf, .doc, .docx, .xlsx, .xls, .csv, .ppt and .pptx formats are supported',
      })
      .optional(),
    isSubjectRequired: z.boolean(),
  })
  .superRefine((data, context) => {
    if (data.isSubjectRequired && (data.subject === undefined || data.subject.trim().length === 0)) {
      context.addIssue({
        path: ['subject'],
        code: z.ZodIssueCode.custom,
        message: 'Subject is required',
      });
    }
  });

type TFormSchema = z.infer<typeof addEmailSchema>;

type TNewEmailDialogProps = {
  modalName: typeof ModalType.SAF_NEW_EMAIL | typeof ModalType.SAF_EMAIL_SUBMITTER;
  showSubject?: boolean;
  defaultSafId?: string;
};

export const NewEmailDialog: React.FC<TNewEmailDialogProps> = (props) => {
  const { modalName, showSubject = false, defaultSafId } = props;

  const formId = useId();

  const { safId: paramSafId } = useParams();

  const safId = defaultSafId ?? paramSafId;

  const { isOpen, toggleModal } = useModal(modalName);

  const { query: correspondenceId } = useQueryParameter({
    queryName: SafEmailQueryName.CORRESPONDENCE,
  });

  const { mutate: sendEmail, isPending: isSendingPending } = useSendSafEmail();
  const { mutate: replyEmail, isPending: isReplyingPending } = useReplySafEmail();

  const isLoading = useMemo(() => isSendingPending || isReplyingPending, [isSendingPending, isReplyingPending]);

  const defaultValues = useMemo(
    () => ({
      subject: '',
      text: '',
      files: [],
      isSubjectRequired: showSubject || !correspondenceId,
    }),
    [correspondenceId, showSubject],
  );

  const form = useForm<TFormSchema>({
    resolver: zodResolver(addEmailSchema),
    mode: 'onChange',
    defaultValues,
  });
  const files = form.watch('files') ?? [];

  const editorConfig = useEditor({
    extensions: [
      StarterKit.configure({
        horizontalRule: false,
        codeBlock: false,
        paragraph: {
          HTMLAttributes: {
            class: 'text-node',
          },
        },
        bulletList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        orderedList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        dropcursor: {
          width: 2,
          class: 'ProseMirror-dropcursor border',
        },
      }),
      Placeholder.configure({
        placeholder: () => 'Enter your message here...',
      }),
      Underline,
      Typography,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      SafLinkExtention.configure({
        autolink: true,
        defaultProtocol: 'https',
      }),
    ],
    editorProps: {
      attributes: {
        role: 'textbox',
        class: 'focus:outline-none h-full',
      },
    },
    content: form.getValues('text'),
    onUpdate: ({ editor }) => {
      const isEmpty = editor.getText().trim().length === 0;
      form.setValue('text', isEmpty ? '' : JSON.stringify(editor.getJSON()), { shouldValidate: true });
    },
  });

  const closeEmailDialog = () => {
    toggleModal(false);
    form.reset(defaultValues);
    editorConfig?.commands.setContent('');
  };

  const dialogOpenChangeHandler = (open: boolean) => {
    if (open) {
      toggleModal(true);
    } else {
      closeEmailDialog();
    }
  };

  const sendEmailClickHandler = (data: TFormSchema) => {
    if (!safId) {
      showErrorToast('System error', 'SAF ID is missing');
      return;
    }

    if (correspondenceId && !showSubject) {
      replyEmail(
        {
          safId,
          correspondenceId,
          message_html: editorConfig?.getHTML() ?? '',
          message_json: JSON.stringify(editorConfig?.getJSON()),
          attachments: data.files ?? [],
        },
        {
          onSuccess: () => {
            closeEmailDialog();
          },
        },
      );
      return;
    }

    sendEmail(
      {
        safId,
        subject: data.subject,
        message_html: editorConfig?.getHTML() ?? '',
        message_json: JSON.stringify(editorConfig?.getJSON()),
        attachments: data.files ?? [],
      },
      {
        onSuccess: () => {
          closeEmailDialog();
        },
      },
    );
  };

  const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      form.setValue('files', [...event.target.files]);
    }
  };

  const removeFileClickHandler = (filename: string) => {
    form.setValue(
      'files',
      files.filter((file) => file.name !== filename),
      { shouldValidate: true },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={dialogOpenChangeHandler}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] w-full max-w-[840px] flex-col">
        <DialogHeader className="flex-row items-center justify-between gap-2">
          <div className="flex w-full flex-col gap-1">
            <DialogTitle className="text-left text-lg font-semibold text-grey-title">
              Email to SAF Submitter
            </DialogTitle>
            <DialogDescription className="sr-only">
              This dialog allows you to send an email to the submitter of the SAF.
            </DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <ScrollArea
          type="auto"
          className="flex size-full max-h-[640px] min-h-[160px] flex-col px-6 py-4"
          scrollBarClassName="p-2 w-[22px]"
        >
          <Form
            providerProps={form}
            id={formId}
            className="flex w-full flex-col gap-4"
            onSubmit={form.handleSubmit(sendEmailClickHandler)}
          >
            {form.getValues('isSubjectRequired') && (
              <FormField
                control={form.control}
                name="subject"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel required>Subject</FormLabel>
                    <FormControl>
                      <Input {...field} isError={!!fieldState.error} placeholder="Enter subject here" type="text" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            <FormField
              control={form.control}
              name="text"
              render={({ field, fieldState }) => (
                <FormItem className="space-y-2">
                  <FormLabel required>Email Body</FormLabel>
                  <EditorToolbar editor={editorConfig} onFilesChange={fileChangeHandler} />
                  {files.length > 0 && (
                    <FileList>
                      {files.map((file) => (
                        <FileItem
                          key={file.name}
                          filename={file.name}
                          fileSize={file.size}
                          onRemove={removeFileClickHandler}
                        />
                      ))}
                    </FileList>
                  )}
                  {form.formState.errors.files && (
                    <Text className="font-primary text-xs font-normal text-error-main">
                      {form.formState.errors.files.message}
                    </Text>
                  )}
                  <FormControl>
                    <EditorContent
                      ref={field.ref}
                      editor={editorConfig}
                      className={cn(
                        'minimal-tiptap-editor flex h-full min-h-[260px] w-full cursor-text flex-col rounded-lg border border-grey-300 px-3 py-2.5 font-primary text-sm font-medium text-grey-950 transition-colors duration-200 focus-within:border-brand-blue-700 hover:border-brand-blue-700 focus:border-brand-blue-700',
                        {
                          'border-error-main': !!fieldState.error,
                        },
                      )}
                    />
                  </FormControl>
                  {editorConfig && <LinkBubbleMenu editor={editorConfig} />}
                  <FormMessage />
                </FormItem>
              )}
            />
          </Form>
        </ScrollArea>
        <Separator />
        <DialogFooter>
          <Button type="button" variant="tertiary" onClick={closeEmailDialog}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" form={formId} isLoading={isLoading} disabled={isLoading}>
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

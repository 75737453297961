import { Link, useParams } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { Button, Text } from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import type { FC } from 'react';

export const SchoolProgramsReport: FC = () => {
  const { schoolId } = useParams();
  return (
    <div className="flex w-full flex-col items-start justify-normal gap-6">
      <Button variant="link" className="h-auto p-0" iconLeft={<PurpleIcon name="chevron-left" />} asChild>
        <Link to={AppRoutes.App.School.Root.makePath(schoolId, 'overview')}>
          Back to School Profile
        </Link>
      </Button>
      <div className="flex w-full flex-col gap-4 rounded-lg border border-grey-200 bg-white shadow-custom-heavy">
        <div className="flex items-center justify-between gap-2 px-4 pt-6">
          <Text variant="size-16" type="body-600">
            Programs
          </Text>
          {/* <Button variant="primary" iconLeft={<PurpleIcon name="download" />}>
            Export
          </Button> */}
        </div>
        <div className="flex items-center justify-center py-32 text-base">
          Work in progress. Programs report will be available soon.
        </div>
      </div>
    </div>
  );
};

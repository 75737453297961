import { GRADE_CHOICES } from '@purple/shared-types';
import { cutGradeName } from '@purple/shared-utils';
import type { TFiltersConfig } from '@purple/ui';

export const colorTrendFiltersConfig: TFiltersConfig = {
  categories: [
    {
      label: 'Current Color',
      value: 'current_color',
      filters: [
        {
          label: 'Red',
          value: 'Red',
        },
        {
          label: 'Yellow',
          value: 'Yellow',
        },
        {
          label: 'Green',
          value: 'Green',
        },
      ],
    },
    {
      label: 'Grade',
      value: 'grade',
      filters: Object.values(GRADE_CHOICES).map((grade) => ({ label: cutGradeName(grade), value: grade })),
    },
  ],
};

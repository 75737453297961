import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getStudentsReportData } from '@purple/shared-services';
import { STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/shared/lib';
import type { TStudentDataReportRequestParameters } from '@purple/shared-types';

export const useStudentsReportData = (parameters: TStudentDataReportRequestParameters) => {
  const { isError, ...rest } = useQuery({
    queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENTS_REPORT_DATA, parameters],
    queryFn: () => getStudentsReportData(parameters),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System message', 'Failed to retrieve report data');
    }
  }, [isError]);

  return { isError, ...rest };
};

import { TakeActionDocumentType, TakeActionType } from '@purple/shared-types';
import { ReadableTakeActionType } from '@purple/shared-utils';
import type { TFiltersConfig } from '@purple/ui';

export const schoolActionsFilterOptions: TFiltersConfig = {
  categories: [
    {
      label: 'Document As',
      value: 'document_as',
      filters: [
        {
          label: 'General',
          value: TakeActionDocumentType.GENERAL,
        },
        {
          label: 'Targeted',
          value: TakeActionDocumentType.TARGETED,
        },
        {
          label: 'Attempted',
          value: TakeActionDocumentType.ATTEMPTED,
        },
      ],
    },
    {
      label: 'Action Type',
      value: 'record_action_type',
      filters: Object.values(TakeActionType).map((type) => ({
        label: ReadableTakeActionType[type],
        value: type,
      })),
    },
  ],
};

import { getAxiosInstance } from '../../../api';
import { ACTIONS } from '../../../constants/private-api';
import type { TExportSchoolActionsReportParameters } from '@purple/shared-types';

export const exportSchoolActionsReport = async ({ schoolId, ...parameters }: TExportSchoolActionsReportParameters) => {
  const axiosInstance = getAxiosInstance();

  const response = await axiosInstance.get<Blob>(ACTIONS.EXPORT_SCHOOL_ACTIONS_REPORT(schoolId), {
    params: parameters,
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  });

  return response.data;
};

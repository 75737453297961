import { getAxiosInstance } from '../../api';
import { STUDENTS_ENDPOINTS } from '../../constants/private-api';
import type { TStudentDataReportRequestParameters } from '@purple/shared-types';

export const exportActiveStudentsReport = async (parameters: TStudentDataReportRequestParameters) => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.get<Blob>(STUDENTS_ENDPOINTS.EXPORT_ACTIVE_STUDENTS_REPORT, {
    params: parameters,
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  });
  return response.data;
};

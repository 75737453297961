import { useParams, useSearchParams } from 'react-router-dom';
import { usePaginate, useSearch, useTimePeriod } from '@purple/hooks';
import { SORT_QUERY_NAME } from '@purple/shared-types';
import { Heading, TableFooterSection } from '@purple/ui';
import { DataTable, DataTableSkeleton } from '~/components';
import { TIME_PERIODS } from '~/constants/options';
import { useSafList } from '~/queries/schools/saf';
import { schoolSafsColumns } from './safsColumnts';
import { SaFsHeaderSection } from './SaFsHeaderSection';

export const SaFs: React.FC = () => {
  const { schoolId } = useParams();
  const [searchParameters] = useSearchParams();

  const { debounceSearch, search } = useSearch();
  const sort = searchParameters.get(SORT_QUERY_NAME) || '';

  const { onPeriodChange, onPeriodClear, timePeriod } = useTimePeriod({
    defaultValue: TIME_PERIODS.this_academic_year,
  });
  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();

  const { isLoading: isDataLoading, data } = useSafList({
    schoolId: schoolId as string,
    queryParams: {
      search: debounceSearch,
      limit,
      offset,
      ordering: sort,
      needs: searchParameters.get('needs'),
      priority: searchParameters.get('priority'),
      saf_owner: searchParameters.get('saf_owner'),
      time_period: timePeriod,
    },
  });

  if (!schoolId) {
    return null;
  }

  return (
    <>
      <div className="flex px-4 pb-4 pt-6">
        <Heading type="heading-600" variant="size-18" className="text-grey-title">
          Total SAFs
        </Heading>
      </div>
      <div className="flex flex-col gap-5">
        <SaFsHeaderSection
          timePeriod={timePeriod}
          onPeriodChange={onPeriodChange}
          onPeriodClear={onPeriodClear}
        />
        <DataTable
          columns={schoolSafsColumns}
          data={data?.results || []}
          isLoading={isDataLoading}
          isFiltersApplied={Boolean(search)}
          emptyStateTitle="No SAFs found"
          emptyStateMessage="There are no SAFs to display. If it an error, please contact support."
          skeleton={<DataTableSkeleton rows={limit} />}
        />
        {data && (
          <TableFooterSection
            currentPage={page}
            pageCount={Math.ceil(data.count / limit)}
            onPageChange={onPageChange}
            rowsPerPage={limit}
            onRowsPerPageChange={onLimitChange}
            totalRows={data?.count || 0}
          />
        )}
      </div>
    </>
  );
};

import { cutGradeName } from '@purple/shared-utils';
import { Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import type { ColumnDef } from '@tanstack/react-table';
import type { TStudentDataReportItem } from '@purple/shared-types';

export const subpopulationReportColumns: ColumnDef<TStudentDataReportItem>[] = [
  {
    accessorKey: 'full_name',
    size: 250,
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600 uppercase">
        Name
      </Text>
    ),
    cell: ({ row }) => row.original.full_name
      ? (
          <Tooltip>
            <TooltipTrigger>
              <Text variant="size-14" type="body-500" className="text-grey-950 line-clamp-1 break-all">
                {row.original.full_name}
              </Text>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent withArrow={false}>
                {row.original.full_name}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        )
      : (<span>—</span>),
  },
  {
    accessorKey: 'grade',
    size: 120,
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600 uppercase">
        Grade
      </Text>
    ),
    cell: ({ row }) => row.original.grade
      ? (
          <Text variant="size-14" type="body-500" className="text-grey-950">
            {cutGradeName(row.original.grade)}
          </Text>
        )
      : (<span>—</span>),
  },
  {
    accessorKey: 'total_safs',
    size: 120,
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600 uppercase">
        Total SAFs
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950">
        {row.original.total_safs ?? 0}
      </Text>
    ),
  },
  {
    accessorKey: 'total_actions',
    size: 120,
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600 uppercase">
        Total Actions
      </Text>
    ),
    cell: ({ row }) => (
      <Text variant="size-14" type="body-500" className="text-grey-950">
        {row.original.total_actions ?? 0}
      </Text>
    ),
  },
  {
    accessorKey: 'subpop',
    header: () => (
      <Text variant="size-12" type="body-600" className="text-grey-600 uppercase">
        Subpopulations
      </Text>
    ),
    cell: ({ row }) => row.original.subpop && row.original.subpop.length > 0
      ? (
          <Tooltip>
            <TooltipTrigger>
              <Text variant="size-14" type="body-500" className="text-grey-950 line-clamp-1 break-all">
                {row.original.subpop.map((subpop) => subpop.name).join(', ')}
              </Text>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent withArrow={false} className="whitespace-pre-wrap">
                {row.original.subpop.map((subpop) => subpop.name).join('\n')}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        )
      : (<span>—</span>),
  },
];
